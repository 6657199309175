@charset "UTF-8";
/*Import font-awesome*/
@import url(~font-awesome/css/font-awesome.min.css);
/*Import Core*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: flex; }

body,
html {
  width: 100%;
  height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

ol,
ul {
  list-style: none; }

hr {
  border: 0;
  height: 1px;
  background: #ccc; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box; }

html,
input,
select,
textarea,
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Light.eot");
  src: url("font/hinted-AvenirLTStd-Light.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Light.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Light.woff") format("woff"), url("font/hinted-AvenirLTStd-Light.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Light.svg#AvenirLTStd-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Book.eot");
  src: url("font/hinted-AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Book.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Book.woff") format("woff"), url("font/hinted-AvenirLTStd-Book.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Roman.eot");
  src: url("font/hinted-AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Roman.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Roman.woff") format("woff"), url("font/hinted-AvenirLTStd-Roman.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Medium.eot");
  src: url("font/hinted-AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Medium.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Medium.woff") format("woff"), url("font/hinted-AvenirLTStd-Medium.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Medium.svg#AvenirLTStd-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("font/fontawesome-webfont.eot?v=4.7.0");
  src: url("font/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"), url("font/fontawesome-webfont.woff2?v=4.7.0") format("woff2"), url("font/fontawesome-webfont.woff?v=4.7.0") format("woff"), url("font/fontawesome-webfont.ttf?v=4.7.0") format("truetype"), url("font/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  font-family: 'Avenir LT Std', 'Helvetica Nueue', sans-serif, 'FontAwesome'; }

html {
  font-size: 62.5%; }

body {
  background-color: #FFFFFA; }

.container {
  background-color: #FFFFFA;
  width: 100%; }

.text_left {
  text-align: left; }

.text_right {
  text-align: right; }

.box-shadow {
  border: 1px solid #094063;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }

.primary_button {
  border-radius: 4px;
  font-size: 1.4rem;
  font-style: normal;
  background-color: #094063;
  color: #FFFFFA;
  margin: 1rem;
  padding: 7px 15px;
  border: 0;
  cursor: pointer;
  outline: none; }
  .primary_button.secondary {
    background-color: #FFFFFA;
    color: #094063;
    border: 2px solid #094063; }
  .primary_button a {
    color: #FFFFFA;
    text-decoration: none; }

button:disabled {
  cursor: not-allowed;
  background: darkgrey !important; }

input[type="number"]:read-only {
  border-color: darkgray !important; }

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  background-color: #ffffff; }
  .login_form .form_wrapper {
    padding: 2rem; }
    .login_form .form_wrapper .form {
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom: 8rem; }
      .login_form .form_wrapper .form input[type=text],
      .login_form .form_wrapper .form input[type=password] {
        width: 100%;
        height: 5rem;
        padding: 1rem;
        border: 1px solid #C0E5E5;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 2rem;
        margin-bottom: 1rem; }
      .login_form .form_wrapper .form button {
        border-radius: 4px;
        font-size: 1.6rem;
        width: 100%;
        height: 5.5rem;
        font-style: normal;
        font-weight: 500;
        background-color: #094063;
        color: #FFFFFA;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 0;
        cursor: pointer; }

.form_logo {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #094063;
  padding: 2rem; }
  .form_logo p {
    font-size: 2.5rem; }

.dash {
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box; }

.column {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }

#left {
  flex-shrink: 0; }

.top-left {
  flex-shrink: 0; }

.top-right {
  flex-shrink: 0;
  display: inline-flex; }

.bottom {
  flex-grow: 1;
  overflow-y: auto;
  position: relative; }

.dashboard {
  display: flex;
  flex-direction: row;
  width: 100%; }

.sidebar {
  width: 20%;
  height: 100%;
  background-color: #094063;
  font-size: 1.6rem;
  min-width: 25rem; }
  .sidebar ul {
    width: 100%; }

.sidebar__top {
  display: flex;
  justify-content: left;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex-direction: column; }
  .sidebar__top p {
    color: #89CBF0;
    font-size: 2rem;
    margin-top: 10px; }
  .sidebar__top img {
    width: 100px;
    max-height: 100px;
    display: block;
    margin: 0 auto;
    border-radius: 10%; }

.dashboard__menu {
  height: 6rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  border-left: 5px solid transparent; }
  .dashboard__menu a {
    text-decoration: none;
    color: #FFFFFA;
    font-style: normal;
    font-weight: 300; }
  .dashboard__menu button {
    font-size: 1.6rem;
    background: none;
    border: none;
    color: white;
    font-weight: 300;
    cursor: pointer; }
  .dashboard__menu .fa {
    margin-right: 1rem;
    color: #FFFFFA; }

.dashboard_profile {
  display: flex;
  padding: 2rem;
  border-radius: 4px;
  margin: 2rem;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }
  .dashboard_profile img {
    width: 250px;
    height: 250px; }

.deleted-profile__info {
  background: rgba(1, 1, 1, 0.75);
  font-size: 5rem;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; }

.profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center; }

.profile__planinfo {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  color: #212121; }

.menu-active {
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 5px solid #89CBF0; }

.content {
  width: 100%;
  position: relative; }

.header {
  height: 8rem;
  background-color: #FFFFFA;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1); }

.button-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem; }

.header__left {
  display: flex;
  align-items: center; }
  .header__left .toggle-nav {
    display: none; }
  .header__left .header__title {
    font-style: normal;
    font-weight: 500;
    color: #094063; }
    .header__left .header__title h2 {
      font-size: 2rem; }

.header__right {
  display: flex;
  align-items: center;
  justify-content: center; }
  .header__right button {
    border-radius: 4px;
    font-size: 1.6rem;
    width: 14rem;
    height: 4rem;
    font-style: normal;
    font-weight: normal;
    background-color: #094063;
    color: #FFFFFA;
    border: 0;
    margin: 0 1rem; }
  .header__right .header__notif button {
    width: 1rem;
    height: 1rem; }
  .header__right .header__notif .notif {
    border: 0;
    background-color: #FFFFFA;
    color: #094063; }
  .header__right .header__search {
    width: 16rem;
    height: 4rem; }
    .header__right .header__search input {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      border: 1px solid #094063;
      padding: 1rem; }

.badge {
  position: relative; }

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: .8rem;
  background: #D72C2C;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal; }

.sidebar__active {
  display: flex;
  flex-direction: column; }

.fa {
  color: #094063; }

.dashboard__table {
  width: 100%;
  text-align: left; }
  .dashboard__table th {
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #e3e3e3;
    font-style: normal;
    font-weight: normal;
    color: #094063;
    height: 6rem;
    vertical-align: middle;
    text-transform: uppercase; }
  .dashboard__table tr:hover {
    background-color: #f5f5f5; }
  .dashboard__table tr.deactivated {
    background-color: #e4e1e1; }
    .dashboard__table tr.deactivated td {
      color: #a2a1a1 !important; }
  .dashboard__table tr.deleted {
    display: none; }
  .dashboard__table tr.expired {
    display: none; }
  .dashboard__table td {
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e3e3e3;
    font-style: normal;
    font-weight: 300;
    color: #212121;
    height: 6rem;
    vertical-align: middle; }
  .dashboard__table a {
    text-decoration: none;
    color: #a9d7f7;
    width: 100%;
    display: flex; }

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); }

.modal.open {
  display: flex; }

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 2rem;
  width: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center; }

.modal-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  color: #094063; }

.modal-close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end; }

.button_link {
  color: #FFFFFA;
  text-decoration: none; }

.form-input {
  border: 0.5px solid #094063;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 40rem;
  max-width: 100%; }

.form-button {
  border: 0;
  border-radius: 4px;
  background-color: #094063;
  padding: 1rem;
  width: 40rem;
  max-width: 100%;
  margin-bottom: 1rem;
  color: #FFFFFA;
  margin-top: 0.5rem; }

.modal-smtext {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  font-weight: bold; }

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid;
  display: flex;
  margin-left: 1.2rem; }

.dot_active {
  color: rgba(0, 128, 11, 0.6); }

.dot_inactive {
  color: rgba(255, 0, 0, 0.6); }

.dot_pending {
  color: rgba(255, 116, 8, 0.6); }

.full_profile {
  display: flex;
  border-radius: 4px;
  margin: 2rem;
  justify-content: space-between;
  flex-direction: column; }
  .full_profile img {
    max-width: 28rem;
    width: 100%; }

.l-full__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem; }

.l-full__info-label {
  letter-spacing: 0.3rem;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: 300;
  text-transform: uppercase; }

.l-full__info-smtext {
  font-size: 1.6rem;
  padding-bottom: 2rem; }

.payment__details {
  display: flex;
  padding: 0 2rem; }

.capitalize {
  text-transform: capitalize; }

.invoice-expanded__headertext {
  font-size: 2rem;
  color: #094063;
  padding: 2rem;
  padding-bottom: 1.2rem;
  text-transform: capitalize; }

.grey_bg {
  background-color: rgba(187, 187, 204, 0.1); }

.dash__tab {
  display: flex;
  padding: 2rem;
  flex-wrap: wrap;
  width: 100%; }
  .dash__tab button {
    border: none;
    width: 50%;
    background-color: #fffffa;
    border-bottom: 4px solid rgba(9, 64, 99, 0.1);
    cursor: pointer; }
  .dash__tab button.activeTab {
    border-bottom: 4px solid #094063;
    color: #094063; }
  .dash__tab button:focus {
    outline: none; }

.tabDashboard {
  display: none;
  flex-direction: column; }

.success-text {
  color: #27AE60 !important;
  font-weight: bold !important; }

.danger-text {
  color: #D72C2C !important;
  font-weight: bold !important; }

.sort_button {
  position: relative; }

.sort_button-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 88%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: absolute;
  left: 10px;
  border-radius: 4px; }
  .sort_button-content a {
    color: #212121;
    padding: 1rem 1.2rem;
    text-decoration: none;
    display: flex;
    font-size: 1.2rem;
    flex-direction: column; }
  .sort_button-content a:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.showDropdown {
  display: flex;
  flex-direction: column; }

.bottom-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: left;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem; }
  .bottom-left img {
    height: 3rem; }

.profile__label {
  letter-spacing: 0.3rem;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: 300;
  text-transform: uppercase; }

.profile__infotext {
  font-size: 2.0rem;
  padding-bottom: 2rem; }

.company-info {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap; }
  .company-info button {
    align-self: flex-end; }
  .company-info img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    width: 25rem;
    height: 25rem; }
  .company-info .usage {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap-reverse;
    overflow: hidden;
    position: relative; }
    .company-info .usage .usage-summary {
      width: 20%;
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
    .company-info .usage .usage-graph {
      width: 75%; }

.button-grey {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1.6rem;
  color: #212121;
  cursor: pointer;
  margin-bottom: 1rem; }

.l_card-holder {
  display: flex;
  width: 100%;
  height: auto;
  padding: 2rem;
  justify-content: flex-start;
  flex-wrap: wrap; }

.l_card {
  min-width: 18rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 10rem;
  padding: 0.5rem;
  margin: 1rem; }

.big_num {
  color: #107bc0;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500; }

.smalltext {
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase; }

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  vertical-align: middle; }
  .switch input {
    display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.addEmployee {
  display: flex;
  flex-direction: column;
  padding: 2rem; }
  .addEmployee h2 {
    font-size: 2rem;
    color: #094063;
    line-height: 2.6rem; }
  .addEmployee p {
    font-size: 1.6rem;
    color: #212121;
    line-height: 2rem; }
  .addEmployee .l-form__explanatory-text {
    padding: 10px 0;
    color: #827C7C;
    font-size: 1.3rem; }

.spreadSheet {
  margin: 1rem;
  width: 50%;
  height: 40rem;
  position: relative; }
  .spreadSheet h4 {
    font-size: 1.4rem;
    line-height: 2rem; }

.addEmpForm {
  margin-right: 1rem;
  height: auto;
  width: 50%; }

.l-addEmployee {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem; }

.addEmployee__invsum {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  margin-top: 2rem; }

.l-form {
  display: flex;
  flex-direction: column; }

input:checked + .slider {
  background-color: #a9d7f7; }

input:focus + .slider {
  box-shadow: 0 0 1px #a9d7f7; }

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px); }

/* Rounded sliders */
.slider.round {
  border-radius: 20px; }

.slider.round:before {
  border-radius: 50%; }

.dash__settings {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  font-size: 1.4rem;
  color: #212121; }
  .dash__settings span {
    display: flex;
    width: 100%;
    justify-content: space-between; }

.alert__banner {
  padding: 2rem 3rem;
  color: white; }
  .alert__banner h3 {
    font-size: 1.7rem;
    margin-bottom: 0.8rem; }
  .alert__banner p {
    font-size: 1.2rem; }

.alert__banner.success {
  background: #449802; }

.alert__banner.error {
  background: #ff491f; }

.client-account-list-wrapper {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .client-account-list-wrapper h2 {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem; }
  .client-account-list-wrapper .client-account-list {
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    margin: 0 auto;
    width: 90%;
    justify-content: center; }
    .client-account-list-wrapper .client-account-list .client-account-list-item {
      margin: 1rem;
      border-radius: 10%;
      border: 1px solid rgba(9, 64, 99, 0.1);
      padding: 1rem;
      width: 250px;
      cursor: pointer;
      justify-content: center;
      display: flex;
      flex-direction: column; }
      .client-account-list-wrapper .client-account-list .client-account-list-item img {
        max-width: 200px; }
      .client-account-list-wrapper .client-account-list .client-account-list-item .info {
        text-align: center; }
        .client-account-list-wrapper .client-account-list .client-account-list-item .info .title {
          font-size: 1.8rem; }
        .client-account-list-wrapper .client-account-list .client-account-list-item .info .address {
          color: #212121; }

.plan-selection-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem; }

.plan-selection__header-wrapper {
  margin-top: 60px; }
  .plan-selection__header-wrapper h2 {
    text-align: center;
    font-size: 2.2rem;
    color: #094063;
    font-weight: 300;
    font-style: normal;
    line-height: 3rem;
    padding: 4rem; }

.plan-selection__duration-wrapper {
  display: flex;
  border: 1px solid #107bc0;
  border-radius: 4px;
  align-self: center; }
  .plan-selection__duration-wrapper .plan_duration_btn {
    padding: 2rem;
    font-size: 1.6rem;
    border: 0;
    background-color: #f2f2f2;
    height: 6rem;
    width: 15rem;
    cursor: pointer; }
  .plan-selection__duration-wrapper .plan_duration_btn_active {
    font-size: 1.8rem;
    background-color: #107bc0;
    color: white; }
  .plan-selection__duration-wrapper .lpdbc {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .plan-selection__duration-wrapper .rpdbc {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px; }

.plan-selection__plans-wrapper {
  display: flex;
  flex-direction: column; }
  .plan-selection__plans-wrapper .plan_container {
    display: flex;
    justify-content: space-around;
    margin: auto;
    margin-top: 4rem;
    padding: 2rem;
    box-shadow: 2px 2px 20px #eee;
    border-radius: 4px; }
    .plan-selection__plans-wrapper .plan_container .plan_item {
      display: flex;
      flex-direction: column;
      min-width: 22rem;
      height: 20rem;
      margin: 2rem;
      justify-content: space-between;
      position: relative;
      z-index: 1; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_name {
        font-size: 2rem;
        color: #212121; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_small_desc {
        font-size: 1.4rem;
        color: #4f4f4f; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_small_link {
        color: #107bc0;
        font-size: 1.2rem; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_name_text {
        font-size: 1.6rem;
        color: #333333;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 500; }
      .plan-selection__plans-wrapper .plan_container .plan_item .price {
        display: flex;
        padding: 2rem 0;
        flex-wrap: wrap; }
        .plan-selection__plans-wrapper .plan_container .plan_item .price .plan_pricetag {
          font-size: 2.4rem;
          color: #27ae60; }
        .plan-selection__plans-wrapper .plan_container .plan_item .price .pricetag_duration {
          font-size: 1.4rem;
          color: #212121; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_qty_select {
        margin: 1rem 0;
        font-size: 1.6rem;
        height: 4rem;
        padding: 1rem;
        border: 1px solid #5199d3;
        background-color: #fffffa;
        color: #212121;
        width: 100%; }
      .plan-selection__plans-wrapper .plan_container .plan_item .plan_select_btn {
        border-radius: 4px;
        padding: 1rem;
        font-size: 1.6rem;
        border: 0;
        background-color: #107bc0;
        color: white; }
    .plan-selection__plans-wrapper .plan_container .plan_item.added:before {
      content: '';
      border-top: 3rem solid #26ae5f;
      position: absolute;
      border-left: 3rem solid transparent;
      top: 0;
      right: 0; }
    .plan-selection__plans-wrapper .plan_container .plan_item.added:after {
      content: '✔';
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 0.5rem;
      padding-top: 0.3rem; }

.checkout-container {
  position: fixed;
  width: 80%;
  background: white;
  display: block;
  z-index: 10; }

.checkout-container.hide {
  display: none; }

.checkout-header {
  display: flex;
  width: 100%;
  border: none;
  justify-content: space-between;
  padding: 1rem 3rem;
  align-items: center;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1); }

.checkout-header__plan-count {
  font-size: 1.8rem;
  width: 100%;
  cursor: pointer; }
  .checkout-header__plan-count h3 {
    display: inline-block; }
  .checkout-header__plan-count h5 {
    color: grey;
    font-size: 1rem;
    margin-left: 2rem; }

.checkout-header__button {
  margin-left: 1.5rem;
  font-weight: 500;
  font-style: normal;
  background-color: #094063;
  border-radius: 4px;
  border: 0;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem; }

.checkout-details {
  display: none; }

.checkout-details.active {
  padding: 1rem 2rem;
  width: 100%;
  border: none;
  display: block; }

.checkout-details__plan-list {
  list-style: circle; }

.checkout-details__list-item {
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .checkout-details__list-item button {
    border: none;
    color: darkred;
    background: none;
    cursor: pointer; }

.delete-enrollee-modal p {
  font-size: 1.7rem; }

.delete-enrollee-modal .button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 2rem 0; }
  .delete-enrollee-modal .button-wrapper button {
    border: none;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0.1rem 2rem;
    cursor: pointer;
    height: min-content;
    align-self: center; }
  .delete-enrollee-modal .button-wrapper .yes {
    color: #1c4f6f; }
  .delete-enrollee-modal .button-wrapper .no {
    color: white;
    background-color: #1c4f6f;
    border-radius: 0.5rem; }

.table-paginator {
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
  justify-content: center;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  background: #FFFFFA;
  box-shadow: 0 0 1px 1px #dedede; }
  .table-paginator button {
    padding: 1rem;
    background: #FFFFFA;
    border-radius: 0.01rem; }
  .table-paginator button:disabled {
    color: white; }
  .table-paginator button:not(:disabled) {
    cursor: pointer; }
  .table-paginator button:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem; }
  .table-paginator button:nth-last-child(1) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
  .table-paginator button:focus {
    outline: none; }
  .table-paginator button.current {
    background: #094063;
    color: #FFFFFA; }
  .table-paginator p {
    margin: 1rem;
    font-size: 2rem; }

@media screen and (max-width: 768px) {
  .login_form {
    font-size: 1.2rem; }
  .form_logo img {
    height: 2.6rem; }
  .form_logo p {
    font-size: 1.5rem; }
  #divider {
    font-size: 3rem; }
  .sidebar__active {
    display: none; }
  .sidebar {
    width: 20%;
    height: 100%;
    background-color: #094063;
    font-size: 1.6rem;
    min-width: 25rem;
    border-right: 1px solid rgba(9, 64, 99, 0.1); }
    .sidebar .sidebar__top {
      display: flex;
      padding-left: 2.5rem;
      align-items: center;
      border-bottom: 1px solid rgba(9, 64, 99, 0.1);
      flex-direction: column; }
      .sidebar .sidebar__top img {
        width: 75px;
        max-height: 75px;
        display: block;
        margin: 0 auto; }
    .sidebar ul {
      width: 100%; }
    .sidebar .dashboard__menu {
      height: 6rem;
      padding-left: 2rem;
      display: flex;
      align-items: center; }
      .sidebar .dashboard__menu a {
        text-decoration: none;
        font-weight: 300; }
      .sidebar .dashboard__menu .fa {
        margin-right: 1rem; }
  .header {
    padding: 1rem; }
  .header__left {
    display: flex;
    align-items: center; }
    .header__left .toggle-nav {
      display: flex;
      color: #094063;
      font-size: 1.55rem;
      border: none;
      margin-right: 1rem;
      background-color: #FFFFFA; }
    .header__left .header__title {
      font-style: normal;
      font-weight: 500;
      color: #094063; }
      .header__left .header__title h2 {
        font-size: 1.6rem; }
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header__right button {
      border-radius: 3px;
      font-size: 1.2rem;
      width: 9rem;
      height: 3rem;
      font-style: normal;
      font-weight: normal;
      background-color: #094063;
      color: #FFFFFA;
      border: 0;
      margin: 0 1rem; }
    .header__right .header__notif button {
      width: 1rem;
      height: 1rem; }
    .header__right .header__notif .notif {
      border: 0;
      background-color: #FFFFFA;
      color: #094063; }
    .header__right .header__search {
      width: 9rem;
      height: 3rem; }
      .header__right .header__search input {
        border-radius: 3px;
        width: 100%;
        height: 100%;
        border: 1px solid #094063;
        padding: 1rem; }
  .dashboard__table th {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    text-transform: uppercase; }
  .dashboard__table td {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    cursor: pointer; }
  .l_card-holder {
    justify-content: center; }
  .l_card {
    width: 100%;
    margin: 0.1rem; }
    .l_card .big_num {
      font-size: 1.8rem; }
  .modal-content {
    width: 100%;
    height: 100%; }
  .dashboard_profile {
    flex-direction: column;
    padding: 0; }
    .dashboard_profile img {
      align-self: center;
      max-width: 100%;
      height: auto; }
  .profile__info {
    padding-top: 3rem; }
  .profile__planinfo {
    padding-top: 1rem; }
  .spreadSheet {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    position: relative; }
  .addEmpForm {
    height: auto;
    width: 100%; }
  .l-addEmployee {
    flex-wrap: wrap; }
  #or {
    margin: auto;
    padding: 1rem; }
  .company-info .usage .usage-summary {
    width: 100%;
    justify-content: center; }
  .company-info .usage .usage-graph {
    width: 100%;
    justify-content: center; }
  .checkout-header__plan-count {
    font-size: 1.5rem; }
  .checkout-header__button {
    margin-left: 1rem;
    padding: 1rem;
    font-size: 1rem; }
  .checkout-container {
    width: 100%; }
  .plan_container {
    flex-direction: column;
    min-height: 104rem;
    align-items: center; }
  .plan_item {
    width: 100%; }
  .plan-selection__duration-wrapper .plan_duration_btn {
    padding: 0;
    font-size: 1.4rem;
    height: 4rem;
    width: 10rem;
    cursor: pointer; }
  .plan-selection__duration-wrapper .plan_duration_btn_active {
    font-size: 1.6rem; }
  .plan-selection__duration-wrapper .plan_duration_copy {
    font-size: 1.8rem;
    line-height: 3rem; }
  .button-header {
    justify-content: center; } }
